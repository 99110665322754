import axios from '@axios'

export default {
  namespaced: true,
  state: {
    userData: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData || {}
    })(),
    permissions: {},
  },
  getters: {
    userData: (state) => state.userData,
    permissions: (state) => state.permissions
  },
  mutations: {
    updateUserData(state, userData) {
      state.userData = userData
    },
    updatePermissions(state, permissions) {
      state.permissions = permissions
    }
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/user/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
