const commitmentFunctionality = localStorage.getItem("clientData")
  ? JSON.parse(localStorage.getItem("clientData")).commitment_functionality
  : false

export default [
  // Process
  {
    path: '/organization/process',
    name: 'organization-process-list',
    component: () => import('@/views/organization/process/list/List.vue'),
    meta: {
      pageTitle: commitmentFunctionality ? 'Confirmations List' : 'List all processes',
      breadcrumb: [
        {
          text: commitmentFunctionality ? 'Confirmations' : 'Processes',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/process/add',
    name: 'organization-process-add',
    component: () => import('@/views/organization/process/add/Add.vue'),
    meta: {
      pageTitle: 'Add a new Process',
      breadcrumb: [
        {
          text: 'Processes',
          to: '/organization/process',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/process/edit/:id',
    name: 'organization-process-edit',
    component: () => import('@/views/organization/process/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit this Process',
      breadcrumb: [
        {
          text: 'Processes',
          to: '/organization/process',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Workers
  {
    path: '/organization/workers',
    name: 'organization-worker-list',
    component: () => import('@/views/organization/worker/list/List.vue'),
    meta: {
      pageTitle: 'List all workers',
      breadcrumb: [
        {
          text: 'Workers',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/workers/add',
    name: 'organization-worker-add',
    component: () => import('@/views/organization/worker/add/Add.vue'),
    meta: {
      pageTitle: 'Create a new Worker',
      breadcrumb: [
        {
          text: 'Workers',
          to: '/organization/workers',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/worker/edit/:id',
    name: 'organization-worker-edit',
    component: () => import('@/views/organization/worker/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit this Worker',
      breadcrumb: [
        {
          text: 'Workers',
          to: '/organization/workers',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Roles
  {
    path: '/organization/role/list',
    name: 'organization-role-list',
    component: () => import('@/views/organization/role/list/List.vue'),
    meta: {
      pageTitle: 'List all roles',
      breadcrumb: [
        {
          text: 'Roles',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/role/add',
    name: 'organization-role-add',
    component: () => import('@/views/organization/role/add/Add.vue'),
    meta: {
      pageTitle: 'Add a new Role',
      breadcrumb: [
        {
          text: 'Roles',
          to: '/organization/role/list',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/role/edit/:id',
    name: 'organization-role-edit',
    component: () => import('@/views/organization/role/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit this Role',
      breadcrumb: [
        {
          text: 'Roles',
          to: '/organization/role/list',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Locations
  {
    path: '/organization/location/list',
    name: 'organization-location-list',
    component: () => import('@/views/organization/location/list/List.vue'),
    meta: {
      pageTitle: 'List all locations',
      breadcrumb: [
        {
          text: 'Locations',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/location/add',
    name: 'organization-location-add',
    component: () => import('@/views/organization/location/add/Add.vue'),
    meta: {
      pageTitle: 'Add a new Location',
      breadcrumb: [
        {
          text: 'Locations',
          to: '/organization/location/list',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/location/edit/:id',
    name: 'organization-location-edit',
    component: () => import('@/views/organization/location/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit this Location',
      breadcrumb: [
        {
          text: 'Locations',
          to: '/organization/location/list',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Metadata
  {
    path: '/organization/metadata/list',
    name: 'organization-metadata-list',
    component: () => import('@/views/organization/metadata/list/List.vue'),
    meta: {
      pageTitle: 'List all metadata',
      breadcrumb: [
        {
          text: 'Metadata',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/metadata/add',
    name: 'organization-metadata-add',
    component: () => import('@/views/organization/metadata/add/Add.vue'),
    meta: {
      pageTitle: 'Add a new Metadata',
      breadcrumb: [
        {
          text: 'Metadata',
          to: '/organization/metadata/list',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/metadata/edit/:id/:index',
    name: 'organization-metadata-edit',
    component: () => import('@/views/organization/metadata/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit this Metadata',
      breadcrumb: [
        {
          text: 'Metadata',
          to: '/organization/metadata/list',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/metadata/upload',
    name: 'organization-metadata-upload',
    component: () => import('@/views/organization/metadata/upload/Upload.vue'),
    meta: {
      pageTitle: 'bulkMetadataUpload',
      breadcrumb: [
        {
          text: 'Metadata',
          to: '/organization/metadata/list',
        },
        {
          text: 'Upload',
          active: true,
        },
      ],
    },
  },

  // Confirmation Planning
  {
    path: '/organization/confirmation-planning',
    name: 'organization-confirmation-planning-list',
    component: () => import('@/views/organization/confirmation-planning/list/List.vue'),
    meta: {
      pageTitle: 'Confirmation Planning',
      breadcrumb: [
        {
          text: 'Planning',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/confirmation-planning/add',
    name: 'organization-confirmation-planning-add',
    component: () => import('@/views/organization/confirmation-planning/add/Add.vue'),
    meta: {
      pageTitle: 'Add a new Confirmation Planning',
      breadcrumb: [
        {
          text: 'Planning',
          to: '/organization/confirmation-planning',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/confirmation-planning/edit/:id',
    name: 'organization-confirmation-planning-edit',
    component: () => import('@/views/organization/confirmation-planning/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit this Confirmation Planning',
      breadcrumb: [
        {
          text: 'Planning',
          to: '/organization/confirmation-planning',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Pending Confirmations
  {
    path: '/organization/pending-confirmations',
    name: 'organization-pending-confirmations',
    component: () => import('@/views/organization/pending-confirmations/list/List.vue'),
    meta: {
      pageTitle: 'List all pending confirmations',
      breadcrumb: [
        {
          text: 'Pending Confirmations',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  // Documents
  {
    path: '/organization/documents',
    name: 'organization-document-list',
    component: () => import('@/views/organization/document/list/List.vue'),
    meta: {
      pageTitle: 'List all documents',
      breadcrumb: [
        {
          text: 'Documents',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/document/add',
    name: 'organization-document-add',
    component: () => import('@/views/organization/document/add/Add.vue'),
    meta: {
      pageTitle: 'Add a new Document',
      breadcrumb: [
        {
          text: 'Documents',
          to: '/organization/documents',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/document/edit/:id',
    name: 'organization-document-edit',
    component: () => import('@/views/organization/document/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit this Document',
      breadcrumb: [
        {
          text: 'Documents',
          to: '/organization/documents',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
