import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _ from 'lodash'

export default {
  components: { ToastificationContent },
  methods: {
    showSuccessMessage(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    showErrorMessage(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    async convertInputsToPayload(inputs) {
      const payload = {}
      inputs.map(row => row.singleRow.rowInputs).flat().forEach(input => {
        payload[input.key] = input.value
      })

      return payload
    },
    truncateWords(str, length = 20) {
      return _.truncate(str, { length })
    },
  },
}
