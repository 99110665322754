import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import GlobalMixin from "@/mixins/globalMixin";
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import msal from 'vue-msal'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.mixin(GlobalMixin)
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(msal, {
  auth: {
    clientId: '02e51ad3-e2aa-4424-9268-0f1efcf3d715',
    autoRefreshToken: true
  }
});

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  msal,
  render: h => h(App),
}).$mount('#app')

// PWA functionality
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // Register service worker
    navigator.serviceWorker.register('/sw.js');
  });
}

// Data storage persistent
if (navigator.storage && navigator.storage.persist) {
  // Check if the app data storage is marked as persistent
  navigator.storage.persisted()
    .then((isPersisted) => {
      // console.log(`Is persisted: ${isPersisted}`)
      if (!isPersisted) {
        // Mark app data storage as persistent
        navigator.storage.persist()
          .then(() => {
            // console.log(`Persisted storage granted: ${response}`)
          })
          .catch((error) => console.log(error))
      }
    })
    .catch((error) => console.log(error))
}