import Vue from 'vue'
import endpoints from '@/libs/endpoints'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: endpoints.graphql_endpoint,
  timeout: 100000,
  /* headers: {
    'Access-Control-Allow-Origin' : '*',
  } */
})

export const post = (...args) => axiosIns.post('', ...args)

Vue.prototype.$http = axiosIns
Vue.prototype.$httpPost = post

export default axiosIns
