import { post as $httpPost } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {
    getSystemRoles: state => state.roles,
  },
  mutations: {},
  actions: {
    fetchWorkers(ctx, ...query) {
      return new Promise((resolve, reject) => {
        $httpPost(...query).then(resolve).catch(reject)
      })
    },
    // getLocations(ctx, ...query) {
    //   return new Promise((resolve, reject) => {
    //     $httpPost(...query).then(resolve).catch(reject)
    //   })
    // },
    update(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    deleteWorker(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    updateUser(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    checkUserExist(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    show(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    getWorkersTotalForDropDown(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    getWorkersSupervisedForDropDown(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    getSupervisorsForDropDown(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    getLocationsForDropDown(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    getPrivilegesForDropDown(ctx, ...query) {
      return state.privileges
    },
    addWorker(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    createWorker(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    getWorkersWithSupervisors(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    getCustomFilterForDropDown(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
  },
}