const commitmentFunctionality = localStorage.getItem("clientData")
  ? JSON.parse(localStorage.getItem("clientData")).commitment_functionality
  : false

export default [
  // Process
  {
    path: '/habit/confirmation/new/:id/:user?/:event?',
    name: 'habit-confirmation-new',
    component: () => import('@/views/habit/confirmation/new/New.vue'),
    meta: {
      pageTitle: 'New Confirmation',
      breadcrumb: [
        {
          text: 'Confirmation',
        },
        {
          text: 'New',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/confirmation/view/:id',
    name: 'habit-confirmation-view',
    component: () => import('@/views/habit/confirmation/view/View.vue'),
    meta: {
      pageTitle: 'See Confirmation',
      breadcrumb: [
        {
          text: 'Confirmation',
        },
        {
          text: 'See',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/confirmation/edit/:id',
    name: 'habit-confirmation-edit',
    component: () => import('@/views/habit/confirmation/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit Confirmation',
      breadcrumb: [
        {
          text: 'Confirmation',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/confirmation/dashboard',
    name: 'habit-confirmation-dashboard',
    component: () => import('@/views/habit/confirmation/dashboard/DashboardsContainer.vue'),
    meta: {
      pageTitle: 'Global Compliance Dashboard',
      breadcrumb: [
        {
          text: 'Global Compliance',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/improvement/dashboard',
    name: 'habit-improvement-dashboard',
    component: () => import('@/views/habit/improvement/DashboardsContainer.vue'),
    meta: {
      pageTitle: commitmentFunctionality ? 'Commitments' : 'Oportunidades de Mejora',
      breadcrumb: [
        {
          text: commitmentFunctionality ? 'Commitments' : 'Oportunidades',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/meeting/new',
    name: 'habit-meeting-new',
    component: () => import('@/views/habit/meeting/new/New.vue'),
    meta: {
      pageTitle: 'Daily Dialogue',
      breadcrumb: [
        {
          text: 'Daily Dialogue',
        },
        {
          text: 'Nuevo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/meeting/communications',
    name: 'habit-meeting-communications',
    component: () => import('@/views/habit/meeting/communications/List.vue'),
    meta: {
      pageTitle: 'List all Communications',
      breadcrumb: [
        {
          text: 'Communications',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/meeting/communications/add',
    name: 'habit-meeting-communications-add',
    component: () => import('@/views/habit/meeting/communications/Add.vue'),
    meta: {
      pageTitle: 'Add a new communication',
      breadcrumb: [
        {
          text: 'Communications',
          to: '/habit/meeting/communications',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/meeting/communications/view/:id',
    name: 'habit-meeting-communications-view',
    component: () => import('@/views/habit/meeting/communications/View.vue'),
    meta: {
      pageTitle: 'See Communication',
      breadcrumb: [
        {
          text: 'Communications',
          to: '/habit/meeting/communications',
        },
        {
          text: 'See',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/meeting/communications/edit/:id',
    name: 'habit-meeting-communications-edit',
    component: () => import('@/views/habit/meeting/communications/Edit.vue'),
    meta: {
      pageTitle: 'Edit Communication',
      breadcrumb: [
        {
          text: 'Communications',
          to: '/habit/meeting/communications',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/meeting/customersOpinions',
    name: 'habit-meeting-customersOpinions',
    component: () => import('@/views/habit/customersOpinions/List.vue'),
    meta: {
      pageTitle: 'List all Customers Opinions',
      breadcrumb: [
        {
          text: 'Customers Opinions',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/customersOpinions/list',
    name: 'habit-customersOpinions-list',
    component: () => import('@/views/habit/customersOpinions/List.vue'),
    meta: {
      pageTitle: 'List all Customers Opinions',
      breadcrumb: [
        {
          text: 'Customers Opinions',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/customersOpinions/dashboard',
    name: 'habit-customersOpinions-dashboard',
    component: () => import('@/views/habit/customersOpinions/Dashboard.vue'),
    meta: {
      pageTitle: 'Customer Satisfaction Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/recognition/dashboard',
    name: 'habit-recognition-dashboard',
    component: () => import('@/views/habit/recognition/Dashboard.vue'),
    meta: {
      pageTitle: 'Recognitions Dashboard',
      breadcrumb: [
        {
          text: 'Recognitions',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/happiness/dashboard',
    name: 'habit-happiness-dashboard',
    component: () => import('@/views/habit/happiness/Dashboard.vue'),
    meta: {
      pageTitle: 'Daily Dialogue Dashboard',
      breadcrumb: [
        {
          text: 'Daily Dialogues',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/happiness/measure-of-happiness',
    name: 'habit-happiness-measure-of-happiness',
    component: () => import('@/views/habit/measureOfHappiness/Dashboard.vue'),
    meta: {
      pageTitle: 'Mood Dashboard',
      breadcrumb: [
        {
          text: 'Mood',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/adherence/dashboard',
    name: 'habit-adherence-dashboard',
    component: () => import('@/views/habit/adherence/processes/Dashboard.vue'),
    meta: {
      pageTitle: 'Processes Dashboard',
      breadcrumb: [
        {
          text: 'Processes',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/behaviour/dashboard',
    name: 'habit-behaviour-dashboard',
    component: () => import('@/views/habit/adherence/behaviour/Dashboard.vue'),
    meta: {
      pageTitle: 'Behaviours Dashboard',
      breadcrumb: [
        {
          text: 'Behaviours',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/commitment/dashboard',
    name: 'habit-commitment-dashboard',
    component: () => import('@/views/habit/adherence/commitment/Dashboard.vue'),
    meta: {
      pageTitle: 'Commitment Dashboard',
      breadcrumb: [
        {
          text: 'Commitment',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/daily/dashboard',
    name: 'habit-daily-dashboard',
    component: () => import('@/views/habit/confirmation/daily-dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Daily Compliance Dashboard',
      breadcrumb: [
        {
          text: 'Daily Compliance',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  // Commitment
  {
    path: '/habit/commitment/new/:commitmentId/:workerId?/:eventId?',
    name: 'habit-commitment-new',
    component: () => import('@/views/habit/commitment/new/New.vue'),
    meta: {
      pageTitle: 'New Commitment',
      breadcrumb: [
        {
          text: 'Commitment',
        },
        {
          text: 'New',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/commitment/view/:commitmentId',
    name: 'habit-commitment-view',
    component: () => import('@/views/habit/commitment/view/View.vue'),
    meta: {
      pageTitle: 'See Commitment',
      breadcrumb: [
        {
          text: 'Commitment',
        },
        {
          text: 'See',
          active: true,
        },
      ],
    },
  },
  // Assistance
  {
    path: '/habit/assistance/new/:improvementId',
    name: 'habit-assistance-new',
    component: () => import('@/views/habit/assistance/new/New.vue'),
    meta: {
      pageTitle: 'New Assistance',
      breadcrumb: [
        {
          text: 'Assistance',
        },
        {
          text: 'New',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/assistance/view/:assistanceId',
    name: 'habit-assistance-view',
    component: () => import('@/views/habit/assistance/view/View.vue'),
    meta: {
      pageTitle: 'See Assistance',
      breadcrumb: [
        {
          text: 'Assistance',
          to: '/habit/assistance/list',
        },
        {
          text: 'See',
          active: true,
        },
      ],
    },
  },
  {
    path: '/habit/assistance/list',
    name: 'habit-assistance-list',
    component: () => import('@/views/habit/assistance/list/List.vue'),
    meta: {
      pageTitle: 'List Assistance',
      breadcrumb: [
        {
          text: 'Assistance',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]