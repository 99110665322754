import { post as $httpPost } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {
    getSystemRoles: state => state.roles,
  },
  mutations: {},
  actions: {
    fetchConfirmationPlannings(ctx, ...query) {
      return new Promise((resolve, reject) => {
        $httpPost(...query).then(resolve).catch(reject)
      })
    },
    update(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    deleteConfirmationPlanning(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    show(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    createConfirmationPlanning(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
  },
}