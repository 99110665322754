import { post as $httpPost } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMetadataOptions(ctx, ...query) {
      return new Promise((resolve, reject) => {
        $httpPost(...query).then(resolve).catch(reject)
      })
    },
    update(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    deleteMetadata(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    show(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
    addMetadata(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
  },
}
