import axios, {
  post as $httpPost
} from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    deleteImprovement(ctx, ...query) {
      return new Promise((resolve, reject) => $httpPost(...query).then(resolve).catch(reject))
    },
  },
}
